<!--
 * @Description: 数据中心列表
 * @Author: 小广
 * @Date: 2020-04-15 14:34:48
 * @LastEditors: 小广
 * @LastEditTime: 2020-04-15 15:01:29
 -->
<template>
  <div class="DataExportCenterList-wrapper">
    <list-item :type="0"></list-item>
  </div>
</template>

<script>
import ListItem from './DataExportItem.vue'

export default {
  name: '',
  components: {
    'list-item': ListItem
  },
  data () {
    return {}
  },

  methods: {}
}
</script>

<style scoped lang="scss">
.DataExportCenterList-wrapper {
  height: 100%;
}
</style>
